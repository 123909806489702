import { Circle, LoaderCircle } from 'lucide-react';

export function LoadingSpinner({ size = '[1.25em]' }: { size?: string }) {
  return (
    <span className="relative inline-block">
      <Circle className={`size-${size} animate-spin opacity-10`} />
      <LoaderCircle
        className={`absolute top-0 z-10 size-${size} animate-spin`}
      />
    </span>
  );
}
